import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  useGetProductDetailsQuery,
  useCreateReviewMutation,
  useUpdateReviewMutation,
  useDeleteReviewMutation,
} from '../slices/productsApiSlice';
import Rating from '../components/Rating';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Meta from '../components/Meta';
import { addToCart } from '../slices/cartSlice';

const ProductScreen = () => {
  useEffect(() => {
    document.title = 'Productos';
  }, []);

  const { id: productId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [editingReview, setEditingReview] = useState(null);

  const addToCartHandler = () => {
    dispatch(addToCart({ ...product, qty }));
    navigate('/cart');
  };

  const {
    data: product,
    isLoading,
    refetch,
    error,
  } = useGetProductDetailsQuery(productId);

  const { userInfo } = useSelector((state) => state.auth);

  const [createReview, { isLoading: loadingProductReview }] = useCreateReviewMutation();
  const [updateReview, { isLoading: loadingUpdateReview }] = useUpdateReviewMutation();
  const [deleteReview, { isLoading: loadingDeleteReview }] = useDeleteReviewMutation();

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await createReview({
        productId,
        rating,
        comment,
      }).unwrap();
      refetch();
      toast.success('Reseña agregada correctamente');
      setRating(0);
      setComment('');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const editReviewHandler = async (e) => {
    e.preventDefault();

    try {
      await updateReview({
        productId,
        reviewId: editingReview._id,
        review: { rating, comment },
      }).unwrap();
      refetch();
      toast.success('Reseña actualizada correctamente');
      setRating(0);
      setComment('');
      setEditingReview(null);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const deleteReviewHandler = async (reviewId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta reseña?')) {
      try {
        await deleteReview({ productId, reviewId }).unwrap();
        refetch();
        toast.success('Reseña eliminada correctamente');
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>
      <Link className='btn btn-light my-3' to='/' id='back-button'>
        Volver
      </Link>
      {isLoading ? (
        <Loader id='loading-product' />
      ) : error ? (
        <Message variant='danger' id='error-message'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta title={product.name} description={product.description} />
          <Row>
            <Col md={6}>
              <Image src={product.image} alt={product.name} fluid id='product-image' />
            </Col>
            <Col md={3}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h3 id='product-name'>{product.name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${product.numReviews} Reseñas`}
                    id='product-rating'
                  />
                </ListGroup.Item>
                <ListGroup.Item id='product-price'>Precio: ${product.price}</ListGroup.Item>
                <ListGroup.Item id='product-description'>
                  Descripción: {product.description}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <Card>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <Row>
                      <Col>Precio:</Col>
                      <Col>
                        <strong>${product.price}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Estado:</Col>
                      <Col>
                        {product.countInStock > 0 ? 'Stock disponible' : 'Sin Stock'}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col>Cantidad</Col>
                        <Col>
                          <Form.Control
                            as='select'
                            value={qty}
                            onChange={(e) => setQty(Number(e.target.value))}
                            id='product-qty-select'
                          >
                            {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item>
                    <Button
                      className='btn-block'
                      type='button'
                      disabled={product.countInStock === 0}
                      onClick={addToCartHandler}
                      id='add-to-cart-button'
                    >
                      Añadir al carrito
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className='review'>
            <Col md={6}>
              <h2>Reseñas</h2>
              {product.reviews.length === 0 && <Message id='no-reviews-message'>Sin reseñas</Message>}
              <ListGroup variant='flush'>
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id} id={`review-${review._id}`}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                    {userInfo && userInfo._id === review.user && (
                      <div>
                        <Button
                          variant='light'
                          onClick={() => {
                            setEditingReview(review);
                            setRating(review.rating);
                            setComment(review.comment);
                          }}
                        >
                          Editar
                        </Button>
                        <Button
                          variant='danger'
                          onClick={() => deleteReviewHandler(review._id)}
                        >
                          Eliminar
                        </Button>
                      </div>
                    )}
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>{editingReview ? 'Editar' : 'Escribir'} una reseña de consumidor</h2>

                  {loadingProductReview && <Loader id='loading-review' />}
                  {loadingUpdateReview && <Loader id='loading-update-review' />}
                  {loadingDeleteReview && <Loader id='loading-delete-review' />}

                  {userInfo ? (
                    <Form onSubmit={editingReview ? editReviewHandler : submitHandler} id='review-form'>
                      <Form.Group className='my-2' controlId='rating'>
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as='select'
                          required
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                          id='review-rating-select'
                        >
                          <option value=''>Seleccionar...</option>
                          <option value='1'>1 - Malo</option>
                          <option value='2'>2 - Medio</option>
                          <option value='3'>3 - Bueno</option>
                          <option value='4'>4 - Muy bueno</option>
                          <option value='5'>5 - Excelente</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group className='my-2' controlId='comment'>
                        <Form.Label>Comentar</Form.Label>
                        <Form.Control
                          as='textarea'
                          row='3'
                          required
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          id='review-comment-textarea'
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        disabled={loadingProductReview || loadingUpdateReview}
                        type='submit'
                        variant='primary'
                        id='submit-review-button'
                      >
                        {editingReview ? 'Actualizar' : 'Enviar'}
                      </Button>
                      {editingReview && (
                        <Button
                          variant='secondary'
                          onClick={() => {
                            setEditingReview(null);
                            setRating(0);
                            setComment('');
                          }}
                          id='cancel-edit-button'
                        >
                          Cancelar
                        </Button>
                      )}
                    </Form>
                  ) : (
                    <Message id='login-message'>
                      Por favor, <Link to='/login'>Ingrese</Link> para escribir una reseña
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductScreen;
