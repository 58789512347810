import React, { useState, useEffect} from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ContactScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.title = 'Pagina de contacto';
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar el envío del formulario, por ejemplo, enviarlo a una API
    toast.success('¡Mensaje enviado correctamente!');
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <Container>
      <Row className='justify-content-md-center'>
        <Col xs={12} md={6}>
          <h1>Contacto</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='name'>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type='text'
                placeholder='Ingresa tu nombre'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId='email' className='mt-3'>
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type='email'
                placeholder='Ingresa tu correo electrónico'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId='message' className='mt-3'>
              <Form.Label>Mensaje</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                placeholder='Ingresa tu mensaje'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </Form.Group>
            <Button type='submit' variant='primary' className='mt-3'>
              Enviar
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactScreen;
