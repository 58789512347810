const countries = [
    {
      name: 'Argentina',
      code: 'AR',
      states: [
        { name: 'Buenos Aires', code: 'BA' },
        { name: 'Córdoba', code: 'CD' },
        // Agrega más estados aquí
      ],
    },
    {
      name: 'Estados Unidos',
      code: 'US',
      states: [
        { name: 'California', code: 'CA' },
        { name: 'Texas', code: 'TX' },
        // Agrega más estados aquí
      ],
    },
    // Agrega más países y sus estados aquí
  ];
  
  export default countries;
  