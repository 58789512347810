import React, { useState } from 'react';
import Slider from 'react-slick';
import Product from './Product';
import '../assets/styles/RecommendedSlider.css';

const RecommendedSlider = ({ products }) => {
  const [isDragging, setIsDragging] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const handleButtonClick = (e, product) => {
    e.preventDefault();
    if (!isDragging) {
      window.location.href = `/product/${product._id}`;
    }
  };

  // Solo muestra el slider si hay 3 o más productos
  if (products.length < 3) {
    return null;
  }

  return (
    <div className="recommended-slider">
      <h2 className="slider-title">Productos Recomendados</h2>
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product._id} className="slider-item">
            <div className="image-container">
              <Product product={product} />
              <div className="overlay"></div> {/* Capa transparente encima de la imagen */}
            </div>
            <div className="view-product-button-container">
              <button
                className="view-product-button"
                onClick={(e) => handleButtonClick(e, product)}
              >
                Ver Producto
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default RecommendedSlider;
