import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { useLoginMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  useEffect(() => {
    document.title = 'Ingresa a tu cuenta';
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  useEffect(() => {
    const emailInput = document.getElementById('email-input');
    const passwordInput = document.getElementById('password-input');

    emailInput.setCustomValidity('');
    emailInput.oninvalid = (e) => {
      if (e.target.validity.valueMissing) {
        e.target.setCustomValidity('Por favor, introduce tu correo electrónico.');
      } else if (e.target.validity.typeMismatch) {
        e.target.setCustomValidity('Por favor, incluye un "@" en la dirección de correo electrónico.');
      } else {
        e.target.setCustomValidity('');
      }
    };

    passwordInput.setCustomValidity('');
    passwordInput.oninvalid = (e) => {
      if (e.target.validity.valueMissing) {
        e.target.setCustomValidity('Por favor, introduce tu contraseña.');
      } else {
        e.target.setCustomValidity('');
      }
    };
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (err) {
      toast.error('Por favor ingrese una dirección de correo electrónico válida');
    }
  };

  return (
    <FormContainer>
      <h1>Ingresar</h1>
      <Form onSubmit={submitHandler} id="login-form">
        <Form.Group className='my-2' controlId='email'>
          <Form.Label>Dirección de Email</Form.Label>
          <Form.Control
            type='email'
            placeholder='Ingrese su Email'
            value={email}
            id='email-input'
            onChange={(e) => setEmail(e.target.value)}
            required
          ></Form.Control>
          <Form.Control.Feedback type='invalid'>Por favor, introduce tu correo electrónico.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='my-2' controlId='password'>
          <Form.Label>Contraseña</Form.Label>
          <Form.Control
            type='password'
            placeholder='Ingrese su contraseña'
            value={password}
            id='password-input'
            onChange={(e) => setPassword(e.target.value)}
            required
          ></Form.Control>
          <Form.Control.Feedback type='invalid'>Por favor, introduce tu contraseña.</Form.Control.Feedback>
        </Form.Group>

        <Button disabled={isLoading} type='submit' variant='primary' id='login-button'>
          Ingresar
        </Button>

        {isLoading && <Loader id='loader' />}
      </Form>

      <Row className='py-3'>
        <Col>
          ¿Nuevo cliente?{' '}
          <Link to={redirect ? `/register?redirect=${redirect}` : '/register'} id='register-link'>
            Registrarse
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default LoginScreen;
