import React, { useState, useEffect } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

const TestCasesScreen = () => {
  const [showFunctionalCases, setShowFunctionalCases] = useState(true);
  const [expandedSteps, setExpandedSteps] = useState({});

  const toggleSteps = (index) => {
    setExpandedSteps((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    document.title = 'Escenarios de prueba';
  }, []);

  const functionalTestCases = [
    {
      "title": "Caso de Prueba 1: Registrar Usuario",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Verificar que la página de inicio sea visible con éxito",
        "Hacer clic en el botón 'Ingresar' arriba a la derecha",
        "Verificar que la URL sea 'https://atenea.uno/login'",
        "Hacer clic en el enlace 'Registrarse'",
        "Verificar que la URL contenga 'https://atenea.uno/register'",
        "Completar los detalles: Nombre, Apellido, Correo electrónico, Contraseña, Confirmar Contraseña, Fecha de nacimiento",
        "Seleccionar la casilla de verificación 'Suscríbete a nuestro boletín!'",
        "Seleccionar la opción 'Sí' o 'No' para 'Recibir ofertas especiales de nuestros socios!'",
        "Completar los detalles: Dirección, Dirección 2, País, Estado, Código postal, Número de móvil",
        "Hacer clic en el botón 'Registrarse'",
        "Verificar que el usuario sea redirigido a la página principal",
        "Verificar que el nombre del usuario aparezca arriba a la derecha"
      ]
    },
    {
      "title": "Caso de Prueba 2: Iniciar Sesión con correo electrónico y contraseña correctos",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Verificar que la página de inicio sea visible con éxito",
        "Hacer clic en el botón 'Ingresar' arriba a la derecha",
        "Verificar que la url sea 'https://atenea.uno/login'",
        "Ingresar correo electrónico y contraseña correctos",
        "Hacer clic en el botón 'Ingresar'",
        "Verificar que el usuario sea redirigido a la página principal",
        "Verificar que el nombre del usuario aparezca arriba a la derecha"
      ]
    },
    {
      "title": "Caso de Prueba 3: Iniciar Sesión con correo electrónico y contraseña incorrectos",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Verificar que la página de inicio sea visible con éxito",
        "Hacer clic en el botón 'Ingresar' arriba a la derecha",
        "Verificar que la URL sea 'https://atenea.uno/login'",
        "Ingresar correo electrónico y contraseña incorrectos",
        "Hacer clic en el botón 'Ingresar'",
        "Verificar que se muestre un mensaje de error indicando 'Usuario o contraseña inválidos'"
      ]
    },
    {
      "title": "Caso de Prueba 4: Cerrar Sesión",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Iniciar sesión con correo electrónico y contraseña correctos",
        "Hacer clic en el nombre de usuario arriba a la derecha",
        "Hacer clic en el botón 'Salir'",
        "Verificar que el mensaje 'Usuario deslogueado correctamente' sea visible",
        "Verificar que el usuario sea redirigido a la página de inicio de sesión"
      ]
    },
    {
      "title": "Caso de Prueba 5: Registrar Usuario con correo electrónico existente",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Verificar que la página de inicio sea visible con éxito",
        "Hacer clic en el botón 'Ingresar' arriba a la derecha",
        "Verificar que la URL sea 'https://atenea.uno/login'",
        "Hacer clic en el enlace 'Registrarse'",
        "Verificar que la URL contenga 'https://atenea.uno/register'",
        "Ingresar un nombre, apellido y un correo electrónico ya registrado",
        "Ingresar una contraseña y confirmarla",
        "Hacer clic en el botón 'Registrarse'",
        "Verificar que se muestre un mensaje de error indicando 'Ya existe un usuario con ese mail'"
      ]
    },
    {
      "title": "Caso de Prueba 6: Suscribirse al boletín",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Desplazarse hacia abajo hasta el footer",
        "Ingresar una dirección de correo electrónico válida en el campo de suscripción",
        "Hacer clic en el botón 'Suscribirse'",
        "Verificar que se muestre un mensaje de éxito indicando la suscripción exitosa"
      ]
    },
    {
      "title": "Caso de Prueba 7: Verificar la Página de Casos de Prueba",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Hacer clic en 'Escenarios de Prueba' en el menú de navegación",
        "Verificar que la URL sea 'https://atenea.uno/scenarios'",
        "Verificar que la página de escenarios de prueba sea visible con éxito",
        "Verificar que la sección de Casos Funcionales esté seleccionada por defecto",
        "Contar que haya 26 escenarios en la sección de Casos Funcionales",
        "Hacer clic en el toggle para cambiar a Casos API",
        "Verificar que haya 14 casos en la sección de Casos API"
      ]
    },
    {
      "title": "Caso de Prueba 8: Verificar Todas las Páginas de Productos y Detalles del Producto",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Verificar que la página de inicio (homepage) sea visible con éxito",
        "Seleccionar un producto de la lista de productos en la página de inicio",
        "Verificar que la URL del producto seleccionado tenga el formato 'https://atenea.uno/product/{productId}'",
        "Verificar que la página de detalles del producto sea visible con éxito",
        "Verificar que el título del producto esté visible",
        "Verificar que la imagen del producto esté visible",
        "Verificar que el precio del producto esté visible",
        "Verificar que el estado del producto (Stock Disponible) esté visible",
        "Verificar que el campo para seleccionar la cantidad esté visible y permita seleccionar cantidades",
        "Verificar que el botón 'Añadir al carrito' esté visible y sea clickable",
        "Verificar que la sección de reseñas esté visible",
        "Verificar que el texto 'Sin reseñas' esté visible si no hay reseñas",
        "Verificar que el formulario para escribir una reseña esté visible",
        "Verificar que el mensaje de 'Por favor, Ingrese para escribir una reseña' esté visible si el usuario no está autenticado",
        "Verificar que el botón 'Volver' esté visible y funcional"
      ]
    },
    {
      "title": "Caso de Prueba 9: Buscar Producto",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Verificar que la página de inicio (homepage) sea visible con éxito",
        "Ingresar un término de búsqueda en la barra de búsqueda en la sección de filtros a la izquierda de la pantalla",
        "Hacer clic en el botón 'Buscar'",
        "Verificar que los resultados de búsqueda sean visibles con éxito"
      ]
    },
    {
      "title": "Caso de Prueba 10: Añadir un Producto al Carrito",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Seleccionar un producto de la lista",
        "Hacer clic en 'Añadir al carrito'",
        "Verificar que el usuario sea redirigido a 'http://localhost:3000/cart'",
        "Verificar que el producto añadido aparezca en la pantalla del carrito",
        "Verificar que el icono del carrito en la esquina superior derecha tenga un número 1",
        "Verificar que el texto 'Subtotal (1) artículos' sea visible"
      ]
    },
    {
      "title": "Caso de Prueba 11: Añadir dos Productos Diferentes al Carrito",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Seleccionar el primer producto de la lista",
        "Hacer clic en 'Añadir al carrito'",
        "Verificar que el usuario sea redirigido a 'http://localhost:3000/cart'",
        "Verificar que el primer producto añadido aparezca en la pantalla del carrito",
        "Navegar de nuevo a la página principal",
        "Seleccionar el segundo producto de la lista",
        "Hacer clic en 'Añadir al carrito'",
        "Verificar que el usuario sea redirigido a 'http://localhost:3000/cart'",
        "Verificar que ambos productos añadidos aparezcan en la pantalla del carrito",
        "Verificar que el icono del carrito en la esquina superior derecha tenga un número 2",
        "Verificar que el texto 'Subtotal (2) artículos' sea visible",
        "Verificar que el precio de ambos productos añadidos sea visible en el carrito"
      ]
    },
    {
      "title": "Caso de Prueba 12: Añadir Producto al Carrito y Proceder con la Compra",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Verificar que la URL sea https://atenea.uno/",
        "Verificar que la página muestra 'Últimos productos'",
        "Seleccionar un producto de la lista",
        "Hacer clic en 'Añadir al carrito'",
        "Verificar que el producto se añada al carrito con éxito",
        "Hacer clic en el icono del carrito",
        "Verificar que la URL sea https://atenea.uno/cart",
        "Verificar que la página del carrito muestra 'Carrito de compras'",
        "Verificar que el producto añadido está en la lista del carrito",
        "Verificar que el subtotal muestra la cantidad correcta",
        "Hacer clic en 'Realizar Pedido'",
        "Verificar que la URL sea https://atenea.uno/login?redirect=/shipping"
      ]
    },
    {
      "title": "Caso de Prueba 13: Registro Durante la Compra",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Añadir un producto al carrito",
        "Hacer clic en 'Realizar Pedido'",
        "Verificar que la URL sea https://atenea.uno/login?redirect=/shipping",
        "Verificar que la página de inicio de sesión muestra 'Ingresar'",
        "Hacer clic en el enlace '¿Nuevo cliente? Registrarse'",
        "Verificar que la URL sea https://atenea.uno/register?redirect=/shipping",
        "Verificar que la página de registro muestra 'Registrar'",
        "Completar el formulario de registro",
        "Hacer clic en 'Registrarse'",
        "Verificar que el registro se complete con éxito y se redirige a https://atenea.uno/shipping",
        "Verificar que la página de envío muestra 'Envío'"
      ]
    },    
    {
      "title": "Caso de Prueba 14: Verificar y Editar Detalles de Envío",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Completar el registro y redirigirse a la página de envío",
        "Verificar que la URL sea https://atenea.uno/shipping",
        "Verificar que la página de envío muestra 'Envío'",
        "Verificar que los detalles de envío coinciden con los datos de registro",
        "Hacer clic en 'Continuar'",
        "Verificar que la URL sea https://atenea.uno/payment",
        "Verificar que la página de pago muestra 'Método de pago'"
      ]
    },    
    {
      "title": "Caso de Prueba 15: Seleccionar Método de Pago",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Completar los detalles de envío y redirigirse a la página de pago",
        "Verificar que la URL sea https://atenea.uno/payment",
        "Verificar que la página de pago muestra 'Método de pago'",
        "Seleccionar 'PayPal' como método de pago",
        "Hacer clic en 'Continuar'",
        "Verificar que la URL sea https://atenea.uno/placeorder",
        "Verificar que la página de confirmación muestra 'Resumen de pedido'"
      ]
    },   
    {
      "title": "Caso de Prueba 16: Verificar Información de Pedido y Realizar Pedido",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Completar los detalles de envío y seleccionar el método de pago",
        "Verificar que la URL sea https://atenea.uno/placeorder",
        "Verificar la información de pedido en la página de confirmación",
        "Verificar que la página de confirmación muestra 'Resumen de pedido'",
        "Verificar que el producto y los detalles de envío y método de pago son correctos",
        "Hacer clic en 'Realizar Pedido'",
        "Verificar que se abre el modal de PayPal"
      ]
    },
    //Seguir desde aca    
    {
      "title": "Caso de Prueba 17: Realizar Pedido: Registro antes de la Compra",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Registrarse en el sitio web",
        "Añadir un producto al carrito",
        "Hacer clic en 'Proceder con la compra'",
        "Completar los detalles de envío",
        "Verificar que el pedido se realice con éxito"
      ]
    },
    {
      "title": "Caso de Prueba 18: Realizar Pedido: Inicio de Sesión antes de la Compra",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Iniciar sesión en el sitio web",
        "Añadir un producto al carrito",
        "Hacer clic en 'Proceder con la compra'",
        "Completar los detalles de envío",
        "Verificar que el pedido se realice con éxito"
      ]
    },
    {
      "title": "Caso de Prueba 19: Eliminar Productos del Carrito",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Añadir un producto al carrito",
        "Navegar a la página del carrito",
        "Eliminar el producto del carrito",
        "Verificar que el producto se elimine correctamente"
      ]
    },
    {
      "title": "Caso de Prueba 20: Ver Productos por Categoría",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Hacer clic en 'Categorías' en el menú de navegación",
        "Seleccionar una categoría de la lista",
        "Verificar que los productos de la categoría seleccionada sean visibles"
      ]
    },
    {
      "title": "Caso de Prueba 21: Ver Productos por Marca y Carrito",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Hacer clic en 'Marcas' en el menú de navegación",
        "Seleccionar una marca de la lista",
        "Verificar que los productos de la marca seleccionada sean visibles",
        "Añadir un producto de la marca seleccionada al carrito",
        "Verificar que el producto se añada al carrito con éxito"
      ]
    },
    {
      "title": "Caso de Prueba 22: Buscar Productos y Verificar el Carrito después del Inicio de Sesión",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Iniciar sesión en el sitio web",
        "Buscar un producto utilizando la barra de búsqueda",
        "Añadir un producto de los resultados de búsqueda al carrito",
        "Verificar que el producto se añada al carrito con éxito"
      ]
    },
    {
      "title": "Caso de Prueba 23: Añadir Reseña a un Producto",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Seleccionar un producto de la lista",
        "Desplazarse hacia abajo hasta la sección de reseñas",
        "Añadir una reseña con nombre, correo electrónico y comentario",
        "Hacer clic en el botón 'Enviar'",
        "Verificar que la reseña se añada correctamente"
      ]
    },
    {
      "title": "Caso de Prueba 24: Añadir Producto desde Artículos Recomendados",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Desplazarse hacia abajo hasta la sección de artículos recomendados",
        "Seleccionar un producto recomendado",
        "Añadir el producto al carrito",
        "Verificar que el producto se añada al carrito con éxito"
      ]
    },
    {
      "title": "Caso de Prueba 25: Verificar los Detalles de la Dirección en la Página de Pago",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Añadir un producto al carrito",
        "Hacer clic en 'Proceder con la compra'",
        "Verificar que los detalles de la dirección se muestren correctamente en la página de pago"
      ]
    },
    {
      "title": "Caso de Prueba 26: Descargar Factura después de Realizar un Pedido",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Añadir un producto al carrito",
        "Hacer clic en 'Proceder con la compra'",
        "Completar los detalles de envío y pago",
        "Realizar el pedido",
        "Verificar que se muestre la opción de descargar la factura",
        "Hacer clic en 'Descargar Factura'",
        "Verificar que la factura se descargue correctamente"
      ]
    },
    {
      "title": "Caso de Prueba 27: Verificar el Desplazamiento hacia Arriba usando el Botón 'Flecha'",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Desplazarse hacia abajo en la página",
        "Hacer clic en el botón 'Flecha hacia arriba'",
        "Verificar que la página se desplace hacia arriba correctamente"
      ]
    },
    {
      "title": "Caso de Prueba 28: Verificar el Desplazamiento hacia Arriba sin usar el Botón 'Flecha'",
      "steps": [
        "Lanzar el navegador",
        "Navegar a https://atenea.uno/",
        "Desplazarse hacia abajo en la página",
        "Usar la barra de desplazamiento para volver hacia arriba",
        "Verificar que la página se desplace hacia arriba correctamente"
      ]
    }
  ];

  const apiTestCases = [
    {
      "title": "API 1: Obtener Lista de Productos",
      "steps": [
        "Método de solicitud: GET",
        "URL de API: http://atenea.uno/api/productsList",
        "Código de respuesta: 200",
        "JSON de respuesta: Lista de todos los productos"
      ]
    },
    {
      "title": "API 2: Añadir Producto a la Lista de Productos",
      "steps": [
        "Método de solicitud: POST",
        "URL de API: http://atenea.uno/api/productsList",
        "Código de respuesta: 405",
        "Mensaje de respuesta: Este método de solicitud no está soportado"
      ]
    },
    {
      "title": "API 3: Obtener Lista de Marcas",
      "steps": [
        "Método de solicitud: GET",
        "URL de API: http://atenea.uno/api/brandsList",
        "Código de respuesta: 200",
        "JSON de respuesta: Lista de todas las marcas"
      ]
    },
    {
      "title": "API 4: Añadir Marca a la Lista de Marcas",
      "steps": [
        "Método de solicitud: PUT",
        "URL de API: http://atenea.uno/api/brandsList",
        "Código de respuesta: 405",
        "Mensaje de respuesta: Este método de solicitud no está soportado"
      ]
    },
    {
      "title": "API 5: Buscar Producto",
      "steps": [
        "Método de solicitud: POST",
        "URL de API: http://atenea.uno/api/searchProduct",
        "Parámetro de solicitud: search_product (por ejemplo: top, tshirt, jean)",
        "Código de respuesta: 200",
        "JSON de respuesta: Lista de productos buscados"
      ]
    },
    {
      "title": "API 6: Buscar Producto sin Parámetro de Búsqueda",
      "steps": [
        "Método de solicitud: POST",
        "URL de API: http://atenea.uno/api/searchProduct",
        "Código de respuesta: 400",
        "Mensaje de respuesta: Solicitud incorrecta, el parámetro search_product falta en la solicitud POST"
      ]
    },
    {
      "title": "API 7: Verificar Inicio de Sesión con Detalles Válidos",
      "steps": [
        "Método de solicitud: POST",
        "URL de API: http://atenea.uno/api/verifyLogin",
        "Parámetros de solicitud: email, password",
        "Código de respuesta: 200",
        "Mensaje de respuesta: Usuario existe"
      ]
    },
    {
      "title": "API 8: Verificar Inicio de Sesión sin Parámetro de Correo Electrónico",
      "steps": [
        "Método de solicitud: POST",
        "URL de API: http://atenea.uno/api/verifyLogin",
        "Parámetro de solicitud: password",
        "Código de respuesta: 400",
        "Mensaje de respuesta: Solicitud incorrecta, el parámetro email o password falta en la solicitud POST"
      ]
    },
    {
      "title": "API 9: Eliminar Inicio de Sesión",
      "steps": [
        "Método de solicitud: DELETE",
        "URL de API: http://atenea.uno/api/verifyLogin",
        "Código de respuesta: 200",
        "Mensaje de respuesta: Usuario eliminado"
      ]
    },
    {
      "title": "API 10: Verificar Inicio de Sesión con Detalles Inválidos",
      "steps": [
        "Método de solicitud: POST",
        "URL de API: http://atenea.uno/api/verifyLogin",
        "Parámetros de solicitud: email, password (valores inválidos)",
        "Código de respuesta: 404",
        "Mensaje de respuesta: Usuario no encontrado"
      ]
    },
    {
      "title": "API 11: Crear Cuenta de Usuario",
      "steps": [
        "Método de solicitud: POST",
        "URL de API: http://atenea.uno/api/createAccount",
        "Parámetros de solicitud: name, email, password, title (por ejemplo: Mr, Mrs, Miss), birth_date, birth_month, birth_year, firstname, lastname, company, address1, address2, country, state, city, zipcode, mobile_number",
        "Código de respuesta: 200",
        "Mensaje de respuesta: Usuario creado"
      ]
    },
    {
      "title": "API 12: Eliminar Cuenta de Usuario",
      "steps": [
        "Método de solicitud: DELETE",
        "URL de API: http://atenea.uno/api/deleteAccount",
        "Parámetros de solicitud: email, password",
        "Código de respuesta: 200",
        "Mensaje de respuesta: Usuario eliminado"
      ]
    },
    {
      "title": "API 13: Actualizar Cuenta de Usuario",
      "steps": [
        "Método de solicitud: PUT",
        "URL de API: http://atenea.uno/api/updateAccount",
        "Parámetros de solicitud: name, email, password, title (por ejemplo: Mr, Mrs, Miss), birth_date, birth_month, birth_year, firstname, lastname, company, address1, address2, country, state, city, zipcode, mobile_number",
        "Código de respuesta: 200",
        "Mensaje de respuesta: Usuario actualizado"
      ]
    },
    {
      "title": "API 14: Obtener Detalle de Cuenta de Usuario por Correo Electrónico",
      "steps": [
        "Método de solicitud: GET",
        "URL de API: http://atenea.uno/api/getUserDetailByEmail",
        "Parámetros de solicitud: email",
        "Código de respuesta: 200",
        "JSON de respuesta: Detalle del usuario"
      ]
    }
  ];

  const toggleCases = () => {
    setShowFunctionalCases(!showFunctionalCases);
  };

  return (
    <Row>
      <Col md={12} style={{ marginTop: '20px' }}>
        <h1 className="text-center">Escenarios de Prueba</h1>
        <div id="cases-toggle" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <h3>Casos Funcionales</h3>
          <label className="switch">
            <input type="checkbox" checked={!showFunctionalCases} onChange={toggleCases} style={{ margin: 2 }} id="toggle-switch" />
            <span className="slider round"></span>
          </label>
          <h3>Casos API</h3>
        </div>
        <ListGroup>
          {(showFunctionalCases ? functionalTestCases : apiTestCases).map((testCase, index) => (
            <ListGroup.Item key={index} id={`test-case-${index}`}>
              <div
                onClick={() => toggleSteps(index)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#333',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                }}
                id={`test-case-title-${index}`}
              >
                {testCase.title}
              </div>
              {expandedSteps[index] && (
                <ol
                  style={{
                    paddingLeft: '20px',
                    margin: '10px 0',
                    backgroundColor: '#444',
                    color: 'white',
                    borderRadius: '5px',
                    listStyle: 'none',
                  }}
                  id={`test-case-steps-${index}`}
                >
                  {testCase.steps.map((step, stepIndex) => (
                    <li
                      key={stepIndex}
                      style={{
                        padding: '5px 0',
                        borderBottom: '1px solid #555',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      id={`test-case-${index}-step-${stepIndex}`}
                    >
                      <span
                        style={{
                          marginRight: '10px',
                          width: '20px',
                          textAlign: 'right',
                        }}
                      >
                        {stepIndex + 1}.
                      </span>
                      <span>{step}</span>
                    </li>
                  ))}
                </ol>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
    </Row>
  );
};

export default TestCasesScreen;
