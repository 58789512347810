import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { listProducts, listCategories, listBrands } from '../actions/productActions';
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import StarFilter from '../components/StarFilter';
import RecommendedSlider from '../components/RecommendedSlider'; // Importa el componente de RecommendedSlider
import 'rc-slider/assets/index.css';
import '../assets/styles/HomeScreen.css';
import Paginate from '../components/CompactPaginate';
import { FaArrowUp } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';

const HomeScreen = () => {
  const { keyword, pageNumber = 1 } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const productList = useSelector((state) => state.productList);
  const { loading, error, products = [], page, pages } = productList;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading: loadingCategories, error: errorCategories, categories = [] } = categoryList;

  const brandList = useSelector((state) => state.brandList);
  const { loading: loadingBrands, error: errorBrands, brands = [] } = brandList;

  // Define el estado pageSize
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('');
  const [priceRange, setPriceRange] = useState([0, 3000]);
  const [tempPriceRange, setTempPriceRange] = useState([0, 3000]);
  const [minRating, setMinRating] = useState('');
  const [starRating, setStarRating] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchInput, setSearchInput] = useState(keyword || '');
  const [sortOption, setSortOption] = useState('');
  const minDistance = 10;
  const [showButton, setShowButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pageSize, setPageSize] = useState(6); // Estado inicial con 6 productos por página

  // Mantener los dropdowns cerrados al cargar la página
  useEffect(() => {
    document.querySelectorAll("select").forEach(select => {
      select.size = 1;
    });
  }, []);

  useEffect(() => {
    dispatch(listProducts({
      keyword: searchTerm,
      brand,
      category,
      minPrice: priceRange[0],
      maxPrice: priceRange[1],
      minRating,
      starRating,
      pageNumber,
      pageSize, // Usa el valor dinámico de pageSize
      orderBy: sortOption.split('-')[0],
      orderDirection: sortOption.split('-')[1] || 'asc',
    }));

    if (searchTerm || brand || category || priceRange[0] !== 0 || priceRange[1] !== 3000 || sortOption) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
  }, [dispatch, searchTerm, brand, category, priceRange, minRating, starRating, pageNumber, sortOption, pageSize]); // Agrega pageSize a las dependencias del useEffect

  useEffect(() => {
    dispatch(listCategories({ brand }));
  }, [dispatch, brand]);

  useEffect(() => {
    dispatch(listBrands({ category }));
  }, [dispatch, category]);

  useEffect(() => {
    document.title = 'Atenea Shop';
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const trimmedSearchInput = searchInput.trim();
    if (trimmedSearchInput === '') {
      navigate('/');
    } else {
      setSearchTerm(trimmedSearchInput);
      navigate(`/search/${trimmedSearchInput}`);
    }
  };

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setTempPriceRange([Math.min(newValue[0], tempPriceRange[1] - minDistance), tempPriceRange[1]]);
    } else {
      setTempPriceRange([tempPriceRange[0], Math.max(newValue[1], tempPriceRange[0] + minDistance)]);
    }
  };

  const handleChangeCommitted = (event, newValue) => {
    const [min, max] = newValue;

    if (max - min < minDistance) {
      if (min + minDistance <= 3000) {
        setPriceRange([min, min + minDistance]);
        setTempPriceRange([min, min + minDistance]);
      } else {
        setPriceRange([max - minDistance, max]);
        setTempPriceRange([max - minDistance, max]);
      }
    } else {
      setPriceRange(newValue);
    }
    dispatch(listProducts({ keyword: searchTerm, brand, category, minPrice: newValue[0], maxPrice: newValue[1], minRating, starRating }));
  };

  const clearFilters = () => {
    setBrand('');
    setCategory('');
    setPriceRange([0, 3000]);
    setTempPriceRange([0, 3000]);
    setMinRating('');
    setStarRating(null);
    setFiltersApplied(false);
    setSearchTerm('');
    setSearchInput('');
    setSortOption('');
    navigate('/');
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleProductsPerPageChange = (e) => {
    const newPageSize = parseInt(e.target.value, 10);
    setPageSize(newPageSize);
    navigate(
      keyword
        ? `/search/${keyword}/page/1?pageSize=${newPageSize}`
        : `/?pageNumber=1&pageSize=${newPageSize}`
    );
  };

  const sortProducts = (products, sortOption) => {
    switch (sortOption) {
      case 'price-asc':
        return products.slice().sort((a, b) => a.price - b.price);
      case 'price-desc':
        return products.slice().sort((a, b) => b.price - a.price);
      case 'name-asc':
        return products.slice().sort((a, b) => a.name.localeCompare(b.name));
      case 'name-desc':
        return products.slice().sort((a, b) => b.name.localeCompare(a.name));
      case 'rating':
        return products.slice().sort((a, b) => b.rating - a.rating);
      case 'reviews':
        return products.slice().sort((a, b) => b.numReviews - a.numReviews);
      default:
        return products;
    }
  };

  const sortedProducts = sortProducts(products, sortOption);

  // Verifica que products esté definido y no esté vacío antes de pasarlo al slider
  const recommendedProducts = sortedProducts.slice().reverse().slice(0, 9);

  return (
    <div className="home-screen">
      <div className="filter-toggle">
        <button className="btn btn-primary" onClick={() => setShowModal(true)}>Filtros</button>
      </div>
      <div className="filter-form desktop-only">
        <h2>Filtros</h2>
        <form onSubmit={handleSearch}>
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Buscar productos..."
          />
          <button type="submit" className="btn btn-primary">Buscar</button>
          <select
            className="brand-select"
            value={brand}
            disabled={loadingCategories || loadingBrands}
            onFocus={(e) => (window.innerWidth > 768 ? e.target.size = 6 : e.target.size = 1)}
            onBlur={(e) => (e.target.size = 1)}
            onChange={(e) => {
              setBrand(e.target.value);
              e.target.size = 1;
              e.target.blur();
            }}
          >
            <option value="">Todas las Marcas</option>
            {loadingBrands ? (
              <option>Cargando marcas...</option>
            ) : errorBrands ? (
              <option>Error al cargar marcas...</option>
            ) : (
              brands.map((br) => (
                <option key={br} value={br}>
                  {br}
                </option>
              ))
            )}
          </select>
          <select
            className="category-select"
            value={category}
            disabled={loadingCategories || loadingBrands}
            size={1}
            onFocus={(e) => (window.innerWidth > 768 ? e.target.size = Math.min(categories.length, 6) : e.target.size = Math.min(categories.length, 4))}
            onBlur={(e) => (e.target.size = 1)}
            onChange={(e) => {
              setCategory(e.target.value);
              e.target.size = 1;
              e.target.blur();
            }}
          >
            <option value="">Todas las Categorías</option>
            {loadingCategories ? (
              <option>Cargando categorías...</option>
            ) : errorCategories ? (
              <option>Error al cargar categorías...</option>
            ) : (
              categories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))
            )}
          </select>
          <div className="price-slider">
            <h4>Precio</h4>
            <Box sx={{ width: '100%', maxWidth: 300, margin: '0 auto' }}>
              <Slider
                getAriaLabel={() => 'Minimum distance'}
                value={tempPriceRange}
                onChange={handleChange}
                onChangeCommitted={handleChangeCommitted}
                valueLabelDisplay="auto"
                disableSwap
                min={0}
                max={3000}
              />
              <p>{`$${tempPriceRange[0]} - $${tempPriceRange[1]}`}</p>
            </Box>
          </div>
          <div className="star-filter-section">
            <h4>Rating</h4>
            <StarFilter rating={starRating} setRating={setStarRating} />
          </div>
          <div className="sort-section">
            <h4>Ordenar por</h4>
            <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
              <option value="">Seleccione...</option>
              <option value="price-asc">Precio: Menor a Mayor</option>
              <option value="price-desc">Precio: Mayor a Menor</option>
              <option value="name-asc">Nombre: A-Z</option>
              <option value="name-desc">Nombre: Z-A</option>
            </select>
          </div>
          <div className="products-per-page-select">
            <label htmlFor="productsPerPage">Productos por página:</label>
            <select
              id="productsPerPage"
              value={pageSize}
              onChange={handleProductsPerPageChange}
            >
              <option value={3}>3</option>
              <option value={6}>6</option>
              <option value={9}>9</option>
              <option value={12}>12</option>
            </select>
          </div>
        </form>
        <button onClick={clearFilters} disabled={!filtersApplied} className="btn btn-secondary">
          Borrar filtros
        </button>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} className="mobile-only">
        <Modal.Header closeButton>
          <Modal.Title>Filtros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSearch}>
            <input
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Buscar productos..."
              className="form-control mb-3"
            />
            <button type="submit" className="btn btn-primary mb-3">Buscar</button>
            <select
              className="brand-select"
              value={brand}
              disabled={loadingCategories || loadingBrands}
              onFocus={(e) => (window.innerWidth > 768 ? e.target.size = Math.min(brands.length, 6) : e.target.size = Math.min(brands.length, 4))}
              onBlur={(e) => (e.target.size = 1)}
              onChange={(e) => {
                setBrand(e.target.value);
                e.target.size = 1;
                e.target.blur();
              }}
            >
              <option value="">Todas las Marcas</option>
              {loadingBrands ? (
                <option>Cargando marcas...</option>
              ) : errorBrands ? (
                <option>Error al cargar marcas...</option>
              ) : (
                brands.map((br) => (
                  <option key={br} value={br}>
                    {br}
                  </option>
                ))
              )}
            </select>
            <select
              className="category-select"
              value={category}
              disabled={loadingCategories || loadingBrands}
              size={1}
              onFocus={(e) => (window.innerWidth > 768 ? e.target.size = Math.min(categories.length, 6) : e.target.size = Math.min(categories.length, 4))}
              onBlur={(e) => (e.target.size = 1)}
              onChange={(e) => {
                setCategory(e.target.value);
                e.target.size = 1;
                e.target.blur();
              }}
            >
              <option value="">Todas las Categorías</option>
              {loadingCategories ? (
                <option>Cargando categorías...</option>
              ) : errorCategories ? (
                <option>Error al cargar categorías...</option>
              ) : (
                categories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))
              )}
            </select>
            <div className="price-slider">
              <h4>Precio</h4>
              <Box sx={{ width: '100%', maxWidth: 300, margin: '0 auto' }}>
                <Slider
                  getAriaLabel={() => 'Minimum distance'}
                  value={tempPriceRange}
                  onChange={handleChange}
                  onChangeCommitted={handleChangeCommitted}
                  valueLabelDisplay="auto"
                  disableSwap
                  min={0}
                  max={3000}
                />
                <p>{`$${tempPriceRange[0]} - $${tempPriceRange[1]}`}</p>
              </Box>
            </div>
            <div className="star-filter-section">
              <h4>Rating</h4>
              <StarFilter rating={starRating} setRating={setStarRating} />
            </div>
            <div className="sort-section">
              <h4>Ordenar por</h4>
              <select value={sortOption} onChange={(e) => setSortOption(e.target.value)} className="form-control mb-3">
                <option value="">Seleccione...</option>
                <option value="price-asc">Precio: Menor a Mayor</option>
                <option value="price-desc">Precio: Mayor a Menor</option>
                <option value="name-asc">Nombre: A-Z</option>
                <option value="name-desc">Nombre: Z-A</option>
              </select>
            </div>
            <div className="products-per-page-selector">
              <label htmlFor="pageSize">Productos por página:</label>
              <select
                id="pageSize"
                value={pageSize}
                onChange={handleProductsPerPageChange}
              >
                <option value="3">3</option>
                <option value="6">6</option>
                <option value="9">9</option>
                <option value="12">12</option>
              </select>
            </div>
          </form>
          <button onClick={clearFilters} disabled={!filtersApplied} className="btn btn-secondary">
            Borrar filtros
          </button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="product-list">
        <h1>Últimos productos</h1>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : sortedProducts.length === 0 ? (
          <div className="no-products">
            <Message variant="info">No hay productos que coincidan con los filtros aplicados</Message>
          </div>
        ) : (
          <>
            <div className="row">
              {sortedProducts.map((product) => (
                <div key={product._id} className="col-sm-12 col-md-6 col-lg-4">
                  <Product product={product} />
                </div>
              ))}
            </div>
            <RecommendedSlider products={recommendedProducts} />
          </>
        )}
        <div className="pagination-container">
          <Paginate pages={pages} page={page} keyword={keyword || ''} />
        </div>
      </div>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <FaArrowUp />
        </button>
      )}
    </div>
  );
};

export default HomeScreen;
