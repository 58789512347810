import { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import { savePaymentMethod } from '../slices/cartSlice';
import { toast } from 'react-toastify';
import axios from 'axios';

const PaymentScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress, paymentMethod: savedPaymentMethod } = cart;

  useEffect(() => {
    document.title = 'Página de pagos';

    if (!shippingAddress.address) {
      navigate('/shipping');
    }
  }, [navigate, shippingAddress]);

  const [paymentMethod, setPaymentMethod] = useState(savedPaymentMethod || ''); // Si hay un método guardado, úsalo; de lo contrario, ninguno seleccionado.
  const [cardNumber, setCardNumber] = useState(['', '', '', '']);
  const [cardHolder, setCardHolder] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const validateForm = () => {
    const newErrors = {};

    if (paymentMethod === 'CreditCard') {
      const fullCardNumber = cardNumber.join('');

      if (cardNumber.some((num) => num.length !== 4) || fullCardNumber.length !== 16) {
        newErrors.cardNumber = 'El número de tarjeta debe contener 16 números';
      }

      if (!/^[0-9]{16}$/.test(fullCardNumber)) {
        newErrors.cardNumber = 'Número de tarjeta inválido';
      }

      if (!cardHolder) {
        newErrors.cardHolder = 'El nombre del titular es requerido';
      } else if (!/^[a-zA-Z\s]+$/.test(cardHolder)) {
        newErrors.cardHolder = 'El nombre del titular solo puede contener letras';
      }

      if (!expiryMonth) {
        newErrors.expiryMonth = 'El mes de expiración es requerido';
      }

      if (!expiryYear) {
        newErrors.expiryYear = 'El año de expiración es requerido';
      }

      if (!cvv) {
        newErrors.cvv = 'El CVV es requerido';
      } else if (cvv.length !== 3 || !/^[0-9]{3}$/.test(cvv)) {
        newErrors.cvv = 'CVV inválido';
      }

      if (expiryYear == currentYear && expiryMonth < currentMonth) {
        newErrors.expiryMonth = 'La tarjeta está vencida';
      }
    }

    return newErrors;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    dispatch(savePaymentMethod(paymentMethod)); // Guardar el método de pago seleccionado en el estado global o en la base de datos

    if (paymentMethod === 'CreditCard') {
      const formErrors = validateForm();
      setErrors(formErrors);

      if (Object.keys(formErrors).length === 0) {
        try {
          setIsLoading(true);
          const fullCardNumber = cardNumber.join('');
          const expirationDate = `${expiryMonth}/${expiryYear}`;
          const response = await axios.post('/api/payment', {
            cardNumber: fullCardNumber,
            cardHolder,
            expirationDate,
            cvv,
          });

          setTimeout(() => {
            setIsLoading(false);
            toast.success(response.data.message);
            navigate('/placeorder');
          }, 5000);
        } catch (err) {
          setIsLoading(false);
          toast.error(err?.response?.data?.message || err.message);
        }
      }
    } else if (paymentMethod === 'Efectivo') {
      try {
        setIsLoading(true);
        const response = await axios.post('/api/payment/efectivo');

        setTimeout(() => {
          setIsLoading(false);
          toast.success(response.data.message);
          navigate('/placeorder');
        }, 5000);
      } catch (err) {
        setIsLoading(false);
        toast.error(err?.response?.data?.message || err.message);
      }
    }
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 step3 />
      <h1>Método de pago</h1>
      <Form onSubmit={submitHandler} id="payment-form" noValidate>
        <Form.Group>
          <Form.Label>Método de Pago</Form.Label>
          <div>
            <Form.Check
              type="radio"
              label="Tarjeta de crédito"
              id="CreditCard"
              name="paymentMethod"
              value="CreditCard"
              checked={paymentMethod === 'CreditCard'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <Form.Check
              type="radio"
              label="Efectivo"
              id="Cash"
              name="paymentMethod"
              value="Efectivo" // Cambiado de "Cash" a "Efectivo"
              checked={paymentMethod === 'Efectivo'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </div>
        </Form.Group>

        {paymentMethod === 'CreditCard' && (
          <>
            <Form.Group>
              <Form.Label style={{ color: errors.cardNumber ? 'red' : 'black' }}>Número de tarjeta *</Form.Label>
              <Row>
                {cardNumber.map((num, index) => (
                  <Col key={index} xs={3}>
                    <Form.Control
                      type='text'
                      maxLength='4'
                      value={num}
                      onChange={(e) => {
                        const newCardNumber = [...cardNumber];
                        newCardNumber[index] = e.target.value.replace(/\D/g, ''); // Solo números
                        setCardNumber(newCardNumber);
                      }}
                      isInvalid={!!errors.cardNumber}
                      required
                    />
                  </Col>
                ))}
              </Row>
              {errors.cardNumber && <div style={{ color: 'red', fontSize: '0.875em' }}>{errors.cardNumber}</div>}
            </Form.Group>

            <Form.Group className='my-2'>
              <Form.Label style={{ color: errors.cardHolder ? 'red' : 'black' }}>Titular de la tarjeta *</Form.Label>
              <Form.Control
                type='text'
                placeholder='Nombre del titular'
                value={cardHolder}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                  setCardHolder(value.toUpperCase());
                }}
                isInvalid={!!errors.cardHolder}
                required
              />
              {errors.cardHolder && <div style={{ color: 'red', fontSize: '0.875em' }}>{errors.cardHolder}</div>}
            </Form.Group>

            <Form.Group className='my-2'>
              <Form.Label style={{ color: errors.expiryMonth ? 'red' : 'black' }}>Fecha de expiración *</Form.Label>
              <Row>
                <Col xs={6}>
                  <Form.Control
                    as='select'
                    value={expiryYear}
                    onChange={(e) => {
                      setExpiryYear(e.target.value);
                      setExpiryMonth(''); // Limpiar selección de mes si cambia el año
                    }}
                    isInvalid={!!errors.expiryYear}
                    required
                  >
                    <option value=''>Año</option>
                    {[...Array(14).keys()].map((x) => {
                      const year = x + currentYear;
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Col xs={6}>
                  <Form.Control
                    as='select'
                    value={expiryMonth}
                    onChange={(e) => setExpiryMonth(e.target.value)}
                    isInvalid={!!errors.expiryMonth}
                    required
                    disabled={!expiryYear} // Deshabilitar selección de mes hasta que se seleccione el año
                  >
                    <option value=''>Mes</option>
                    {[...Array(12).keys()].map((x) => {
                      const month = x + 1;
                      return (
                        <option key={month} value={month} disabled={expiryYear == currentYear && month < currentMonth}>
                          {month}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
              {errors.expiryMonth && <div style={{ color: 'red', fontSize: '0.875em' }}>{errors.expiryMonth}</div>}
              {errors.expiryYear && <div style={{ color: 'red', fontSize: '0.875em' }}>{errors.expiryYear}</div>}
            </Form.Group>

            <Form.Group className='my-2'>
              <Form.Label style={{ color: errors.cvv ? 'red' : 'black' }}>CVV *</Form.Label>
              <Form.Control
                type='text'
                maxLength='3'
                value={cvv}
                onChange={(e) => setCvv(e.target.value.replace(/\D/g, ''))} // Solo números
                isInvalid={!!errors.cvv}
                required
              />
              {errors.cvv && <div style={{ color: 'red', fontSize: '0.875em' }}>{errors.cvv}</div>}
            </Form.Group>
          </>
        )}

        <Button type='submit' variant='primary' id="continue-button">
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{' '}
              Procesando...
            </>
          ) : (
            'Continuar'
          )}
        </Button>
      </Form>
    </FormContainer>
  );
};

export default PaymentScreen;
