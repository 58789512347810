import React from 'react';
import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const CompactPaginate = ({ pages, page, keyword = '', isAdmin = false }) => {
  const maxPagesToShow = 4;
  let startPage = 1;
  let endPage = maxPagesToShow;

  if (page > maxPagesToShow / 2) {
    startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
    endPage = Math.min(pages, startPage + maxPagesToShow - 1);
  }

  if (endPage - startPage < maxPagesToShow - 1) {
    startPage = Math.max(1, endPage - maxPagesToShow + 1);
  }

  return (
    pages > 1 && (
      <Pagination>
        {startPage > 1 && (
          <LinkContainer
            key="first"
            to={
              !isAdmin
                ? keyword
                  ? `/search/${keyword}/page/1`
                  : `/page/1`
                : `/admin/productlist/${page + 1}`
            }
          >
            <Pagination.First />
          </LinkContainer>
        )}
        {page > 1 && (
          <LinkContainer
            key="prev"
            to={
              !isAdmin
                ? keyword
                  ? `/search/${keyword}/page/${page - 1}`
                  : `/page/${page - 1}`
                : `/admin/productlist/${page - 1}`
            }
          >
            <Pagination.Prev />
          </LinkContainer>
        )}
        {[...Array(endPage - startPage + 1).keys()].map(x => (
          <LinkContainer
            key={startPage + x}
            to={
              !isAdmin
                ? keyword
                  ? `/search/${keyword}/page/${startPage + x}`
                  : `/page/${startPage + x}`
                : `/admin/productlist/${startPage + x}`
            }
          >
            <Pagination.Item active={startPage + x === page}>
              {startPage + x}
            </Pagination.Item>
          </LinkContainer>
        ))}
        {page < pages && (
          <LinkContainer
            key="next"
            to={
              !isAdmin
                ? keyword
                  ? `/search/${keyword}/page/${page + 1}`
                  : `/page/${page + 1}`
                : `/admin/productlist/${page + 1}`
            }
          >
            <Pagination.Next />
          </LinkContainer>
        )}
        {endPage < pages && (
          <LinkContainer
            key="last"
            to={
              !isAdmin
                ? keyword
                  ? `/search/${keyword}/page/${pages}`
                  : `/page/${pages}`
                : `/admin/productlist/${pages}`
            }
          >
            <Pagination.Last />
          </LinkContainer>
        )}
      </Pagination>
    )
  );
};

export default CompactPaginate;
