import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useRegisterMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import countries from '../data/countries';  // Asegúrate de tener una lista de países y estados

const RegisterScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [dob, setDob] = useState(null); // Inicia en null para que esté en blanco
  const [subscribe, setSubscribe] = useState(false);
  const [specialOffers, setSpecialOffers] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  useEffect(() => {

    document.title = 'Registro de usuario';

    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  useEffect(() => {
    if (country) {
      setState('');  // Reset state when country changes
    }
  }, [country]);

  const validateEmail = (email) => {
    // Validar el formato del email
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    // Validar que la contraseña tenga al menos 6 caracteres, un número y un símbolo
    const re = /^(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{6,}$/;
    return re.test(password);
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Validar formato de teléfono (esto es un ejemplo básico, puedes ajustar según tus necesidades)
    const re = /^\+?[1-9]\d{1,14}$/;
    return re.test(phoneNumber);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!name) newErrors.name = 'El nombre es requerido';
    if (!lastName) newErrors.lastName = 'El apellido es requerido';
    if (!email) {
      newErrors.email = 'El correo electrónico es requerido';
    } else if (!validateEmail(email)) {
      newErrors.email = 'Formato de email inválido';
    }
    if (!password) {
      newErrors.password = 'La contraseña es requerida';
    } else if (!validatePassword(password)) {
      newErrors.password = 'La contraseña debe tener al menos 6 caracteres, un número y un símbolo';
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = 'La confirmación de la contraseña es requerida';
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Las contraseñas no coinciden';
    }
    if (!dob) {
      newErrors.dob = 'La fecha de nacimiento es requerida';
    } else {
      const today = new Date();
      const minAgeDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
      if (dob > minAgeDate) {
        newErrors.dob = 'Debes tener al menos 18 años';
      }
    }
    if (!address) newErrors.address = 'La dirección es requerida';
    if (!country) newErrors.country = 'El país es requerido';
    if (!state) newErrors.state = 'El estado es requerido';
    if (!zipcode) {
      newErrors.zipcode = 'El código postal es requerido';
    } else if (zipcode.length < 4) {
      newErrors.zipcode = 'El código postal debe tener al menos 4 dígitos';
    }
    if (!mobileNumber) {
      newErrors.mobileNumber = 'El número de móvil es requerido';
    } else if (!validatePhoneNumber(mobileNumber)) {
      newErrors.mobileNumber = 'Formato de número de móvil inválido';
    }
    if (!specialOffers) newErrors.specialOffers = 'Debes seleccionar una opción';

    return newErrors;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      try {
        const res = await register({
          name,
          email,
          password,
          dob,
          subscribe,
          specialOffers,
          lastName,
          address,
          address2,
          country,
          state,
          zipcode,
          mobileNumber,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        navigate(redirect);
      } catch (err) {
        setErrors({ api: err?.data?.message || err.error });
      }
    }
  };

  return (
    <FormContainer>
      <h1>Registrar</h1>
      <Form onSubmit={submitHandler} id="register-form">
        <Form.Group className='my-2'>
          <Form.Label htmlFor='name-input' style={{ color: errors.name ? 'red' : 'black' }}>Nombre *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Ingrese un nombre'
            value={name}
            id="name-input"
            onChange={(e) => setName(e.target.value)}
            isInvalid={!!errors.name}
          ></Form.Control>
          {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='last-name-input' style={{ color: errors.lastName ? 'red' : 'black' }}>Apellido *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Ingrese un apellido'
            value={lastName}
            id="last-name-input"
            onChange={(e) => setLastName(e.target.value)}
            isInvalid={!!errors.lastName}
          ></Form.Control>
          {errors.lastName && <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='email-input' style={{ color: errors.email ? 'red' : 'black' }}>Dirección de Email *</Form.Label>
          <Form.Control
            type='email'
            placeholder='Ingrese un Email'
            value={email}
            id="email-input"
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={!!errors.email}
          ></Form.Control>
          {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='password-input' style={{ color: errors.password ? 'red' : 'black' }}>Contraseña *</Form.Label>
          <Form.Control
            type='password'
            placeholder='Ingrese una contraseña'
            value={password}
            id="password-input"
            onChange={(e) => setPassword(e.target.value)}
            isInvalid={!!errors.password}
          ></Form.Control>
          {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='confirm-password-input' style={{ color: errors.confirmPassword ? 'red' : 'black' }}>Confirmar contraseña *</Form.Label>
          <Form.Control
            type='password'
            placeholder='Confirmar contraseña'
            value={confirmPassword}
            id="confirm-password-input"
            onChange={(e) => setConfirmPassword(e.target.value)}
            isInvalid={!!errors.confirmPassword}
          ></Form.Control>
          {errors.confirmPassword && <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='dob-input' style={{ color: errors.dob ? 'red' : 'black' }}>Fecha de nacimiento *</Form.Label>
          <DatePicker
            selected={dob}
            onChange={(date) => setDob(date)}
            id="dob-input"
            className="form-control"
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            showMonthDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            placeholderText="Seleccione una fecha"
          />
          {errors.dob && <div style={{ color: 'red', fontSize: '0.875em' }}>{errors.dob}</div>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Check
            type='checkbox'
            label='Suscríbete a nuestro boletín!'
            checked={subscribe}
            id="subscribe-input"
            onChange={(e) => setSubscribe(e.target.checked)}
          ></Form.Check>
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label style={{ color: errors.specialOffers ? 'red' : 'black' }}>¿Recibir ofertas especiales de nuestros socios? *</Form.Label>
          <div>
            <Form.Check
              inline
              label="Sí"
              type="radio"
              id="special-offers-yes"
              name="specialOffers"
              value="yes"
              checked={specialOffers === 'yes'}
              onChange={(e) => setSpecialOffers(e.target.value)}
            />
            <Form.Check
              inline
              label="No"
              type="radio"
              id="special-offers-no"
              name="specialOffers"
              value="no"
              checked={specialOffers === 'no'}
              onChange={(e) => setSpecialOffers(e.target.value)}
            />
          </div>
          {errors.specialOffers && <div style={{ color: 'red', fontSize: '0.875em' }}>{errors.specialOffers}</div>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='address-input' style={{ color: errors.address ? 'red' : 'black' }}>Dirección *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Ingrese la dirección'
            value={address}
            id="address-input"
            onChange={(e) => setAddress(e.target.value)}
            isInvalid={!!errors.address}
          ></Form.Control>
          {errors.address && <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='address2-input'>Dirección 2</Form.Label>
          <Form.Control
            type='text'
            placeholder='Ingrese la dirección 2'
            value={address2}
            id="address2-input"
            onChange={(e) => setAddress2(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='country-input' style={{ color: errors.country ? 'red' : 'black' }}>País *</Form.Label>
          <Form.Control
            as='select'
            value={country}
            id="country-input"
            onChange={(e) => setCountry(e.target.value)}
            isInvalid={!!errors.country}
          >
            <option value=''>Seleccione un país</option>
            {countries.map((c) => (
              <option key={c.code} value={c.name}>
                {c.name}
              </option>
            ))}
          </Form.Control>
          {errors.country && <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='state-input' style={{ color: errors.state ? 'red' : 'black' }}>Estado *</Form.Label>
          <Form.Control
            as='select'
            value={state}
            id="state-input"
            onChange={(e) => setState(e.target.value)}
            isInvalid={!!errors.state}
            disabled={!country}
          >
            <option value=''>Seleccione un estado</option>
            {country && countries.find((c) => c.name === country)?.states.map((s) => (
              <option key={s.code} value={s.name}>
                {s.name}
              </option>
            ))}
          </Form.Control>
          {errors.state && <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='zipcode-input' style={{ color: errors.zipcode ? 'red' : 'black' }}>Código postal *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Ingrese el código postal'
            value={zipcode}
            id="zipcode-input"
            onChange={(e) => setZipcode(e.target.value)}
            isInvalid={!!errors.zipcode}
          ></Form.Control>
          {errors.zipcode && <Form.Control.Feedback type="invalid">{errors.zipcode}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className='my-2'>
          <Form.Label htmlFor='mobile-number-input' style={{ color: errors.mobileNumber ? 'red' : 'black' }}>Número de móvil *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Ingrese el número de móvil'
            value={mobileNumber}
            id="mobile-number-input"
            onChange={(e) => setMobileNumber(e.target.value)}
            isInvalid={!!errors.mobileNumber}
          ></Form.Control>
          {errors.mobileNumber && <Form.Control.Feedback type="invalid">{errors.mobileNumber}</Form.Control.Feedback>}
        </Form.Group>

        {errors.api && <div style={{ color: 'red', marginBottom: '1rem' }}>{errors.api}</div>}

        <Button disabled={isLoading} type='submit' variant='primary' id="register-button">
          Registrarse
        </Button>

        {isLoading && <Loader id="register-loader" />}
      </Form>

      <Row className='py-3'>
        <Col>
          ¿Ya tiene una cuenta?{' '}
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'} id="login-link">
            Ingrese
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default RegisterScreen;
