import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Message from '../components/Message';
import Loader from '../components/Loader';
import {
  useDeliverOrderMutation,
  useGetOrderDetailsQuery,
} from '../slices/ordersApiSlice';

const OrderScreen = () => {
  const { id: orderId } = useParams();

  const {
    data: order,
    refetch,
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId);

  const [deliverOrder, { isLoading: loadingDeliver }] =
    useDeliverOrderMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    document.title = 'Realiza tu orden';
  }, []);

  const deliverHandler = async () => {
    await deliverOrder(orderId);
    refetch();
  };

  // Función para generar la factura en PDF
  const generateInvoice = (order) => {
    const doc = new jsPDF();

    // Título del documento
    doc.text('Factura de Compra', 10, 10);

    // Información básica del pedido
    doc.text(`Pedido: ${order._id}`, 10, 20);
    doc.text(`Fecha: ${new Date(order.createdAt).toLocaleDateString()}`, 10, 30);

    // Información del usuario
    doc.text('Método de Pago:', 10, 40);
    doc.text(`${order.paymentMethod}`, 10, 50);
    doc.text(`Estado del pago: ${order.isPaid ? 'Pagado' : 'No Pagado'}`, 10, 60);

    // Generar tabla de productos
    const items = order.orderItems.map((item, index) => [
      index + 1,
      item.name,
      item.qty,
      `$${item.price.toFixed(2)}`,
      `$${(item.qty * item.price).toFixed(2)}`,
    ]);

    doc.autoTable({
      startY: 70,
      head: [['#', 'Artículo', 'Cantidad', 'Precio unitario', 'Total']],
      body: items,
    });

    // Información adicional de precios
    doc.text(`Artículos: $${order.itemsPrice.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 10);
    doc.text(`Envío: $${order.shippingPrice.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 20);
    doc.text(`Impuesto: $${order.taxPrice.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 30);
    doc.text(`Total: $${order.totalPrice.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 40);

    // Guardar el PDF con un nombre descriptivo
    doc.save(`factura_${order._id}.pdf`);
  };

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger' id='message-error'>{error.data.message}</Message>
  ) : (
    <>
      <h1>Pedido {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Envío</h2>
              <p>
                <strong>Nombre: </strong> {order.user.name}
              </p>
              <p>
                <strong>Email: </strong>{' '}
                <a href={`mailto:${order.user.email}`} id='user-email'>{order.user.email}</a>
              </p>
              <p>
                <strong>Dirección:</strong>
                {order.shippingAddress.address}, {order.shippingAddress.city}{' '}
                {order.shippingAddress.postalCode},{' '}
                {order.shippingAddress.country}
              </p>
              {order.isDelivered ? (
                <Message variant='success' id='message-delivered'>
                  Entregado en {order.deliveredAt}
                </Message>
              ) : (
                <Message variant='danger' id='message-not-delivered'>No Entregado</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Método de pago</h2>
              <p>
                <strong>Método: </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant='success' id='message-paid'>Pagado en {order.paidAt}</Message>
              ) : (
                <Message variant='danger' id='message-not-paid'>No pagado</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Artículos solicitados</h2>
              {order.orderItems.length === 0 ? (
                <Message id='message-empty-order'>El pedido está vacío</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index} id={`order-item-${index}`}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                            id={`item-image-${index}`}
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`} id={`item-link-${index}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4} id={`item-price-${index}`}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Resumen del pedido</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Artículos</Col>
                  <Col id='items-price'>${order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Envío</Col>
                  <Col id='shipping-price'>${order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Impuesto</Col>
                  <Col id='tax-price'>${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col id='total-price'>${order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {loadingDeliver && <Loader id='loader-deliver' />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <Button
                      type='button'
                      className='btn btn-block'
                      onClick={deliverHandler}
                      id='button-deliver'
                    >
                      Marcar como entregado
                    </Button>
                  </ListGroup.Item>
                )}
              <ListGroup.Item>
                <Button
                  type='button'
                  className='btn btn-block'
                  onClick={() => generateInvoice(order)}
                  id='button-download-invoice'
                >
                  Descargar Factura
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
