import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import './../assets/styles/index.css';

const Product = ({ product }) => {
  return (
    <Card className='my-3 p-3 rounded'>
      <Link to={`/product/${product._id}`}>
        <Card.Img src={product.image} variant='top' className='product-image' />
      </Link>

      <Card.Body>
        <Link id={`enlace-producto-${product._id}`} to={`/product/${product._id}`}>
          <Card.Title as='div' className='product-title'>
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as='div' className='product-category'>
          <strong>Categoría: </strong>
          <br/>
          {product.category}
        </Card.Text>
        <Card.Text as='div'>
          <Rating value={product.rating} text={`${product.numReviews}`} />
        </Card.Text>

        <Card.Text as='h3'>${product.price}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;
