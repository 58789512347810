import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Message from '../components/Message';
import CheckoutSteps from '../components/CheckoutSteps';
import { useCreateOrderMutation, useUpdateOrderToSimulatedPaidMutation } from '../slices/ordersApiSlice';
import { clearCartItems } from '../slices/cartSlice';

const PlaceOrderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSimulating, setIsSimulating] = useState(false);

  const cart = useSelector((state) => state.cart);

  const [createOrder, { isLoading: creatingOrder, error: createOrderError }] = useCreateOrderMutation();
  const [updateOrderToSimulatedPaid, { isLoading: loadingSimulatedPay, error: simulatedPayError }] = useUpdateOrderToSimulatedPaidMutation();

  useEffect(() => {
    document.title = 'Ingresar orden';

    if (!cart.shippingAddress.address) {
      navigate('/shipping');
    } else if (!cart.paymentMethod) {
      navigate('/payment');
    }
  }, [cart.paymentMethod, cart.shippingAddress.address, navigate]);

  const placeOrderHandler = async () => {
    setIsSimulating(true);
    try {
      const res = await createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      }).unwrap();

      if (cart.paymentMethod === "Efectivo") {
        toast.success("Orden creada exitosamente");
      } else {
        const paymentResponse = await updateOrderToSimulatedPaid(res._id).unwrap();
        console.log('Payment Response:', paymentResponse); // Verifica que el pedido se haya actualizado correctamente
        toast.success("Pago exitoso");
      }

      setTimeout(() => {
        dispatch(clearCartItems());
        setIsSimulating(false);
        navigate(`/order/${res._id}`);
      }, 5000);
    } catch (err) {
      setIsSimulating(false);
      toast.error(err.data?.message || err.message);
    }
  };

  const formatPrice = (price) => {
    const numericPrice = Number(price);
    return !isNaN(numericPrice) ? numericPrice.toFixed(2) : '0.00';
  };

  return (
    <>
      <CheckoutSteps step1 step2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Envío</h2>
              <p>
                <strong>Dirección:</strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city}{' '}
                {cart.shippingAddress.postalCode},{' '}
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Método de pago</h2>
              <strong>Método: </strong>
              {cart.paymentMethod}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Artículos solicitados</h2>
              {cart.cartItems.length === 0 && !isSimulating ? (
                <Message id="empty-cart-message">Tu carrito está vacío</Message>
              ) : (
                <ListGroup variant='flush'>
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index} id={`cart-item-${index}`}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                            id={`item-image-${index}`}
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`} id={`item-link-${index}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4} id={`item-price-${index}`}>
                          {item.qty} x ${formatPrice(item.price)} = ${formatPrice(item.qty * item.price)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Resumen de pedido</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Artículos</Col>
                  <Col id="items-price">${formatPrice(cart.itemsPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Envío</Col>
                  <Col id="shipping-price">${formatPrice(cart.shippingPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Impuesto</Col>
                  <Col id="tax-price">${formatPrice(cart.taxPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col id="total-price">${formatPrice(cart.totalPrice)}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {createOrderError && (
                  <Message variant='danger' id="order-error-message">{createOrderError.data?.message || createOrderError.message}</Message>
                )}
                {simulatedPayError && (
                  <Message variant='danger' id="order-error-message">{simulatedPayError.data?.message || simulatedPayError.message}</Message>
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type='button'
                  className='btn-block'
                  disabled={cart.cartItems.length === 0 || isSimulating}
                  onClick={placeOrderHandler}
                  id="place-order-button"
                >
                  {creatingOrder || loadingSimulatedPay || isSimulating ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                      Procesando...
                    </>
                  ) : (
                    'Realizar pedido'
                  )}
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PlaceOrderScreen;
