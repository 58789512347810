import React from 'react';
import { Navbar, Nav, Container, NavDropdown, Badge } from 'react-bootstrap';
import { FaShoppingCart, FaUser, FaBook, FaEnvelope } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import logo from '../assets/logo.png';
import { resetCart } from '../slices/cartSlice';
import { toast } from 'react-toastify';

const Header = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      dispatch(resetCart());
      toast.success('Usuario deslogueado correctamente');
      navigate('/login');
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogoClick = () => {
    navigate('/'); // Navega a la página principal
    window.location.reload(); // Recarga la página para eliminar filtros
  };

  return (
    <header>
      <Navbar bg='primary' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <Navbar.Brand id="atenea-shop-logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
            <img src={logo} alt='AteneaShop' />
            AteneaShop
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <LinkContainer id='link-navbar-escenarios' to='/scenarios'>
                <Nav.Link>
                  <FaBook /> Escenarios de Prueba
                </Nav.Link>
              </LinkContainer>
              <LinkContainer id='link-carrito' to='/cart'>
                <Nav.Link>
                  <FaShoppingCart /> Carrito
                  {cartItems.length > 0 && (
                    <Badge pill bg='success' style={{ marginLeft: '5px' }}>
                      {cartItems.reduce((a, c) => a + c.qty, 0)}
                    </Badge>
                  )}
                </Nav.Link>
              </LinkContainer>
              <LinkContainer id='link-contacto' to='/contact'>
                <Nav.Link>
                  <FaEnvelope /> Contacto
                </Nav.Link>
              </LinkContainer>
              {userInfo ? (
                <>
                  <NavDropdown title={userInfo.name} id='username'>
                    <LinkContainer to='/profile'>
                      <NavDropdown.Item>Perfil</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      Salir
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <LinkContainer id='navbar-link-ingresar' to='/login'>
                  <Nav.Link>
                    <FaUser /> Ingresar
                  </Nav.Link>
                </LinkContainer>
              )}

              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Productos</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Pedidos</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Usuarios</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
