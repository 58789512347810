import React from 'react';
import { FaStar } from 'react-icons/fa';
import '../assets/styles/index.css'; // Asegúrate de crear un archivo CSS para los estilos personalizados

const StarFilter = ({ rating, setRating }) => {
  const handleStarClick = (starValue) => {
    if (rating === starValue) {
      setRating(null); // Si ya está seleccionado, deselecciona
    } else {
      setRating(starValue);
    }
  };

  return (
    <div className="star-filter">
      {[1, 2, 3, 4, 5].map((star) => (
        <span key={star} onClick={() => handleStarClick(star)} style={{ cursor: 'pointer' }}>
          <FaStar color={rating >= star ? 'orange' : 'gray'} />
        </span>
      ))}
    </div>
  );
};

export default StarFilter;
