import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import '../assets/styles/Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (!email) {
      setError('Por favor, ingrese una dirección de correo electrónico.');
    } else if (!validateEmail(email)) {
      setError('Por favor, ingrese una dirección de correo electrónico válida.');
    } else {
      setError('');
      // Aquí puedes manejar la suscripción, por ejemplo, enviarla a una API
      toast.success('¡Suscripción exitosa!');
      setEmail('');
    }
  };

  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-md-center py-4">
          <Col xs={12} md={6} className="text-center">
            <h5>Suscripción</h5>
            <Form noValidate onSubmit={handleSubscribe}>
              <Form.Group controlId="formBasicEmail" className="d-flex flex-column align-items-center">
                <div className="d-flex">
                  <Form.Control
                    type="email"
                    placeholder="Ingrese su dirección de correo"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className={`email-input ${error ? 'is-invalid' : ''}`}
                  />
                  <Button type="submit" variant="warning" className="ml-2">
                    Suscribirse
                  </Button>
                </div>
                {error && <div className="invalid-feedback d-block">{error}</div>}
              </Form.Group>
            </Form>
            <p className="mt-3">Reciba las actualizaciones más recientes de nuestro sitio.</p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center py-3">
            <p>Atenea Shop &copy; {currentYear}</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
